body {
    margin: 0;
    font-family: 'BrandonText', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
    overflow-x: hidden;
}

b {
    font-weight: 600;
}


/* Place the google places autocomplete dropdown results above the material-ui dialog with zindex: 1300 */
/* Ref: https://github.com/twbs/bootstrap/issues/4160 */
.pac-container {
    z-index: 1301 !important;
}

@font-face {
    font-family: 'Value Serif Pro';
    font-style: normal;
    font-weight: 700;
    src: url('./Fonts/value-serif-bold-pro.ttf') format('truetype');
}
@font-face {
    font-family: 'Apercu Pro';
    font-style: normal;
    font-weight: 400;
    src: url('./Fonts/apercu-regular-pro.ttf') format('truetype');
}
