.form-class-stripe {
  max-width: 80%;
  width:100%;
  margin: 50px auto;
}
.user-error{
  background-color: #e4584c;
  color:#fff;
  text-align: center;
  padding:10px;
  border-radius: 5px;
}
.coupon-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 25px;
}
.coupon-input{
  border:none;
  box-shadow: #000;
  width:50%;
  border-radius: 50px;
}

/* label {
  color: #6b7c93;
  font-weight: 300;
  letter-spacing: 0.025em;
  margin-top: 16px;
  display: block;
} */

.button-container{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.button-class-stripe {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  width:70%;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 25px;
  font-size: 15px;
  font-weight: normal;
  /* text-transform: uppercase; */
  letter-spacing: 0.025em;
  background-color: #000;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 35px;
}

.button-class-stripe:hover {
  color: #fff;
  cursor: pointer;
  background-color: #000;
  /* #7795f8 */
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.button-class-stripe[disabled] {
  cursor: auto;
  background-color: white;
  color:#000;
  border: 1px solid #000
}
/* 
input,
select {
  display: block;
  border: none;
  font-size: 18px;
  margin: 10px 0 20px 0;
  max-width: 100%;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
  color: #424770;
  letter-spacing: 0.025em;
  width: 500px;
} */

input::placeholder {
  color: #aab7c4;
}

.result,
.error {
  font-size: 16px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}

.error {
  color: #e4584c;
}

.result {
  color: #666ee8;
}

/* 
The StripeElement class is applied to the Element container by default.
More info: https://stripe.com/docs/stripe-js/reference#element-options
*/

.StripeElement,
.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
  border-radius: 4px;
  background: white;
}

.StripeElement--focus,
.StripeElementIdeal--focus,
.StripeElementP24--focus,
.StripeElementEPS--focus,
.StripeElementFpx--focus {
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px,
    rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.loading {
  height: 41.6px;
  opacity: 0.6;
}

.StripeElementIdeal,
.StripeElementP24,
.StripeElementEPS,
.StripeElementFpx {
  padding: 0;
}

.card-error{
  color:"red"
}

@media screen and (max-width: 1000px) {
  .button-class-stripe {
    font-size: 15px;
  }
}
@media screen and (max-width: 380px) {
  .button-class-stripe {
    font-size: 12px;
  }
}